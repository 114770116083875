body {
  margin: 0px;
  font-family: "Bree Rg", "Addington CF", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  max-width: 100%;
  background: rgb(240, 225, 205);
  background: linear-gradient(
    250deg,
    rgba(240, 225, 205, 1) 0%,
    rgba(255, 255, 255, 1) 27%,
    rgba(254, 252, 250, 1) 84%,
    rgba(240, 225, 205, 1) 100%
  );
  background-repeat: no-repeat;
  background-image: url("Assets/background-lines.png");
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  font-family: "Bree Rg";
  scroll-behavior: "smooth";
}

body {
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Bree Rg";
  src: url("./fonts/Bree/Bree-Regular.eot");
  src: url("./fonts/Bree/Bree-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Bree/Bree-Regular.woff2") format("woff2"),
    url("./fonts/Bree/Bree-Regular.woff") format("woff"),
    url("./fonts/Bree/Bree-Regular.ttf") format("truetype"),
    url("./fonts/Bree/Bree-Regular.svg#Bree-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bree Rg";
  src: url("./fonts/Bree/Bree-Bold.eot");
  src: url("./fonts/Bree/Bree-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Bree/Bree-Bold.woff2") format("woff2"),
    url("./fonts/Bree/Bree-Bold.woff") format("woff"),
    url("./fonts/Bree/Bree-Bold.ttf") format("truetype"),
    url("./fonts/Bree/Bree-Bold.svg#Bree-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bree Rg";
  src: url("./fonts/Bree/Bree-Light.eot");
  src: url("./fonts/Bree/Bree-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Bree/Bree-Light.woff2") format("woff2"),
    url("./fonts/Bree/Bree-Light.woff") format("woff"),
    url("./fonts/Bree/Bree-Light.ttf") format("truetype"),
    url("./fonts/Bree/Bree-Light.svg#Bree-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Bree Rg";
  src: url("./fonts/Bree/Bree-Oblique.eot");
  src: url("./fonts/Bree/Bree-Oblique.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Bree/Bree-Oblique.woff2") format("woff2"),
    url("./fonts/Bree/Bree-Oblique.woff") format("woff"),
    url("./fonts/Bree/Bree-Oblique.ttf") format("truetype"),
    url("./fonts/Bree/Bree-Oblique.svg#Bree-Oblique") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Addington CF";
  src: url("./fonts/Addington.CF/AddingtonCF-Regular.eot");
  src: url("./fonts/Addington.CF/AddingtonCF-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Addington.CF/AddingtonCF-Regular.woff2") format("woff2"),
    url("./fonts/Addington.CF/AddingtonCF-Regular.woff") format("woff"),
    url("./fonts/Addington.CF/AddingtonCF-Regular.ttf") format("truetype"),
    url("./fonts/Addington.CF/AddingtonCF-Regular.svg#AddingtonCF-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Addington CF";
  src: url("./fonts/Addington.CF/AddingtonCF-Bold.eot");
  src: url("./fonts/Addington.CF/AddingtonCF-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Addington.CF/AddingtonCF-Bold.woff2") format("woff2"),
    url("./fonts/Addington.CF/AddingtonCF-Bold.woff") format("woff"),
    url("./fonts/Addington.CF/AddingtonCF-Bold.ttf") format("truetype"),
    url("./fonts/Addington.CF/AddingtonCF-Bold.svg#AddingtonCF-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Addington CF";
  src: url("./fonts/Addington.CF/AddingtonCF-Thin.eot");
  src: url("./fonts/Addington.CF/AddingtonCF-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Addington.CF/AddingtonCF-Thin.woff2") format("woff2"),
    url("./fonts/Addington.CF/AddingtonCF-Thin.woff") format("woff"),
    url("./fonts/Addington.CF/AddingtonCF-Thin.ttf") format("truetype"),
    url("./fonts/Addington.CF/AddingtonCF-Thin.svg#AddingtonCF-Thin")
      format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Addington CF";
  src: url("./fonts/Addington.CF/AddingtonCF-RegularItalic.eot");
  src: url("./fonts/Addington.CF/AddingtonCF-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Addington.CF/AddingtonCF-RegularItalic.woff2") format("woff2"),
    url("./fonts/Addington.CF/AddingtonCF-RegularItalic.woff") format("woff"),
    url("./fonts/Addington.CF/AddingtonCF-RegularItalic.ttf") format("truetype"),
    url("./fonts/Addington.CF/AddingtonCF-RegularItalic.svg#AddingtonCF-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

/********* --------------- mobile ----------------************/

@media only screen and (max-width: 800px) {
  body {
    background: rgb(240, 225, 205);
    background: radial-gradient(
      circle,
      rgba(240, 225, 205, 0.22762608461353295) 55%,
      rgba(255, 255, 255, 1) 75%
    );
    background-image: url("./Assets/background-lines.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 1024px) {
  body {
    background: rgb(255, 255, 255);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(240, 225, 205, 0.8354692218684349) 100%
    );
    background-image: url("./Assets/background-lines.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* ------------- TABLET -------------------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  body {
    background: rgb(255, 255, 255);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(240, 225, 205, 0.40940126050420167) 100%
    );
    background-image: url("./Assets/background-lines.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
